export default {
   anonymous: {
      create: '/anonymous',
      update: (id) => `/anonymous/${id}`,
      get: (id) => `/anonymous/${id}`,
   },

   integrations: {
      zoomToken: `/integration/zoom/video-sdk/token`,
   },
   upload: {
      createFile: '/upload',
      getFile: (id) => `/file/${id}`,
      deleteFile: (id) => `/file/${id}`,
   },
   stripe: {
      prices: `/stripe/prices`,
      subscriptionPrices: (currencyCode = 'AED') =>
         `/stripe/prices?type=subscription&currency=${currencyCode}`,
      checkoutSession: `/stripe/checkout-session`,
      reimbursableList: (currentPage) =>
         `/stripe/reimbursable?page=${currentPage}`,
      subscriptionDetail: `/stripe/subscription`,
      getTransactions: `/stripe/transactions`,
      cancelPlan: `/stripe/cancel`,
      reactivate: `/stripe/reactivate`,
      getTopup: (currencyCode = 'AED') =>
         `/stripe/prices?type=one_time&currency=${currencyCode}`,
      updatePaymentMethod: `/stripe/update-payment-method-url`,
   },
   auth: {
      userRole: '/info',
      signout: '/signout',
      refresh: '/refresh-token',
   },
   content: {
      therapyReasons: '/content/therapy-reasons',
      countries: '/content/countries',
      insurances: '/content/insurances',
      therapyTypes: '/content/therapy-types',
      languages: '/content/languages',
      gender: '/content/gender',
      plans: '/content/plans',
      ageGroups: '/content/age-groups',
      certificates: '/content/therapist/certificates',
      specialities: '/content/therapist/specialities',
   },
   therapist: {
      availabilitySlot: (date) => `/therapist/available/${date}`,
      auth: {
         signin: '/therapist/signin',
         signup: '/therapist/signup',
         verifyToken: '/therapist/verify-token',
         resendVerifyToken: '/therapist/resend-token',
         forgetPassword: 'therapist/forgot-password',
         resetPassword: 'therapist/reset-password',
      },
      profile: {
         timezone: '/therapist/profile/timezone',
         get: '/therapist/profile',
         update: '/therapist/profile',
         info: '/therapist/info',
         educationLicense: '/therapist/profile/licenses-educations',
         updateEducationLicense: '/therapist/profile/license-education',
      },
      clientSearch: `/therapist/search/clients`,
      clients: `/therapist/clients`,
      getClient: (id) => `/therapist/client/${id}`,
      clinicalFiles: {
         list: (id) => `/therapist/client/${id}/clinical-files`,
         create: (id) => `/therapist/client/${id}/clinical-file`,
         detail: (id, file_id) =>
            `/therapist/client/${id}/clinical-file/${file_id}`,
         delete: (id, file_id) =>
            `/therapist/client/${id}/clinical-file/${file_id}`,
         update: (id, file_id) =>
            `/therapist/client/${id}/clinical-file/${file_id}`,
      },
      sessions: {
         getBookings: `therapist/session/bookings`,
         getStats: `/therapist/monthly-stats`,
         getPastSessions: (clientId) =>
            `/therapist/client/${clientId}/sessions`,
         reschedule: (id) => `therapist/session/${id}/reschedule`,
         detail: (id) => `/therapist/session/${id}`,
         overview: (id) => `/therapist/session/${id}/overview`,
         book: `/therapist/session/book`,
         attend: (id) => `therapist/session/${id}/attendance`,
         cancel: (id) => `/therapist/session/${id}/cancel`,
         pendingAction: (id, type) => `/therapist/session/${id}/action/${type}`,
         latest: `/therapist/session/latest`,
         last: `/therapist/session/last`,
         updateSession: (id) => `/therapist/session/${id}`,

         report: (id) => `/therapist/session/${id}/report`,
         availabilityMonth: (date) =>
            `/therapist/session/available/month/${date}`,
         availabilitySlot: (date) => `/therapist/session/available/day/${date}`,
         log: (id) => `/therapist/session/${id}/log`,
      },
      Speciality: '/therapist/speciality',
      schedule: {
         calendar: `/therapist/calendar`,
         getSchedule: `/therapist/schedule`,
         putSchedule: `/therapist/schedule`,
      },
      customAvailability: {
         get: `/therapist/custom-availabilities`,
         put: (id) => `/therapist/custom-availability/${id}`,
         post: `/therapist/custom-availability`,
         delete: (id) => `/therapist/custom-availability/${id}`,
      },
      settings: {
         get: `/therapist/availability-settings`,
         put: `/therapist/availability-settings`,
      },
      chat: {
         getConversations: '/chat/conversations',
         getMessages: (id) => `/therapist/chat/${id}/messages`,
         sendMessage: '/therapist/chat',
      },
      journal: {
         get: (clientId, journalId) =>
            `/therapist/client/${clientId}/journal/${journalId}`,
         list: (clientId) => `therapist/client/${clientId}/journals`,
      },
      account: {
         changePassword: `/therapist/change-password`,
         changeEmail: `/therapist/change-email`,
      },
   },
   client: {
      content: {
         careAssessmentQuestions: '/content/care-assessment-questions',
      },
      survey: {
         get: `/client/survey`,
         answer: (id) => `/client/survey/questions/${id}/response`,
      },
      account: {
         myPlan: `/client/my-plan`,
         coverage: `/client/coverage`,
         paymentMethod: `/client/payment-method`,
         changePassword: `/client/change-password`,
         changeEmail: `/client/change-email`,
      },
      sessions: {
         book: `/client/session/book`,
         info: `client/info`,
         reschedule: (id) => `/client/session/${id}/reschedule`,
         cancel: (id) => `/client/session/${id}/cancel`,
         accept: (id) => `/client/session/${id}/accept`,
         availabilityMonth: (date) =>
            `/client/session/therapist/available/month/${date}`,
         availabilitySlot: (date) =>
            `/client/session/therapist/available/day/${date}`,
         getBooking: `client/session/bookings`,
         attend: (id) => `client/session/${id}/attendance`,
         latest: `/client/session/latest`,
         last: `/client/session/last`,
         getStats: `/client/session/booking/stats`,
         updateSession: (id) => `/client/session/${id}`,
         log: (id) => `/client/session/${id}/log`,
         detail: (id) => `/client/session/${id}`,
      },
      therapistRecomendation: '/client/therapist-recommendations',
      therapistSelect: (id) => `/client/therapist/${id}`,
      selectedTherapist: `/client/therapist`,
      therapistEducationLicense: `/client/therapist/licenses-educations`,
      auth: {
         signin: '/client/signin',
         signup: '/client/signup',
         verifyToken: '/client/verify-token',
         resendVerifyToken: '/client/resend-token',
         forgetPassword: 'client/forgot-password',
         resetPassword: 'client/reset-password',
      },
      profile: {
         timezone: '/client/profile/timezone',
         get: '/client/profile',
         update: '/client/profile',
      },
      chat: {
         getConversations: '/chat/conversations',
         getMessages: (id) => `/client/chat/${id}/messages`,
         sendMessage: '/client/chat',
         getChatById: (id) => `/chat/conversation/${id}`,
      },
      journal: {
         therapistView: `/client/journal/therapist-view`,
         get: (id) => `/client/journal/${id}`,
         delete: (id) => `/client/journal/${id}`,
         edit: (id) => `/client/journal/${id}`,
         create: `/client/journal`,
         list: 'client/journals',
      },
      benefitCode: {
         getTherapyTypes: '/client/therapy-types',
      },
   },
   company: {
      verify: '/client/company/employee/verify',
   },
   insurance: {
      verify: '/client/insurance/verify',
   },
};
